@font-face {
  font-family: 'Minecraft-Regular';
  src:url('fonts/Minecraft-Regular.ttf.woff') format('woff'),
  url('fonts/Minecraft-Regular.ttf.svg#Minecraft-Regular') format('svg'),
  url('fonts/Minecraft-Regular.ttf.eot'),
  url('fonts/Minecraft-Regular.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

.closeButton{
  position: absolute;
  right:10px;
  top:0px;
}

#inWarImg{
  position: absolute;
  left: 0px;
  width: 62px;
}

#infoImg{
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
}

#checkImg{
  position: absolute;
  right: 8px;
  bottom:9px;
  width: 24px;
}

#checkImg2{
  position: absolute;
  right: 8px;
  bottom:89px;
  width: 24px;
}


.champHover{
  position: absolute;
  width: 300px;
}

a:link {
  color: papayawhip;
}

/* visited link */
a:visited {
  color: papayawhip;
}

/* mouse over link */
a:hover {
  color: wheat;
}

/* selected link */
a:active {
  color: white;
}

.red{
  color : red;
}

audio, embed {
  position: absolute;
  z-index: -9999;
}

.accordionOpen{
  background-color: #351e2b
}

.centered {
  position: fixed; /* or absolute */
  width: 90%;
  bottom: 52%;
  left: 50%;
  align-content: center;
  align-items: center;
  text-align: center;
  horiz-align: center;
  /* bring your own prefixes */
  transform: translate(-50%, 0%);
}

.centeredDC {
  position: fixed; /* or absolute */
  width: 90%;
  bottom: 39%;
  left: 50%;
  align-content: center;
  align-items: center;
  text-align: center;
  horiz-align: center;
  /* bring your own prefixes */
  transform: translate(-50%, 0%);
}

.centeredCS {
  position: fixed; /* or absolute */
  width: 90%;
  bottom: 50%;
  left: 50%;
  align-content: center;
  align-items: center;
  text-align: center;
  horiz-align: center;
  /* bring your own prefixes */
  transform: translate(-50%, 0%);
}

.won {
  color: mediumseagreen;
}

.white{
  color:white;
  font-size: 36px;
}

.vecticalAlign{
  height: 90%;
  text-align:center;
  display:table;
  width:100%;
}


.overall{
  position: fixed;
  height: 100%;
  width: 100%;

}

body, html {
  height: 100%;
  margin: 0;
  background-color: rgba(12, 9, 6, 0);
  color: white;
  font-family: 'Minecraft-Regular';
  font-smooth: never;
  -webkit-font-smoothing : none;
}

.vingette{
  box-shadow: 0 0 100px black inset;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

body{
  background-color: #0c0906;
}



.font{
  font-family: 'Minecraft-Regular';
  font-smooth: never;
  -webkit-font-smoothing : none;
}

h5 {
  font-smooth: never;
  -webkit-font-smoothing : none;
  font-family: 'Minecraft-Regular';
  font-size: 24px;
  color : papayawhip;
  margin: 0px;
  padding: 0px;
  text-shadow:
          0 0 7px #000000,
          0 0 10px #000000;
}

.textShadow{

  text-shadow:
          0 0 7px #000000,
          0 0 10px #000000;
}

h6 {
  pointer-events:none;
  font-smooth: never;
  -webkit-font-smoothing : none;
  font-family: 'Minecraft-Regular';
  font-size: 16px;
  color : papayawhip;
  margin: 0px;
  padding: 0px;
  text-shadow:
          0 0 7px #000000,
          0 0 10px #000000;
}



h1 {
  font-smooth: never;
  -webkit-font-smoothing : none;
  font-size: 40px;
  color : #ffffff;
  padding: 0px;
  margin:0px;
}

.specialP {
  pointer-events:none;
  font-smooth: never;
  -webkit-font-smoothing : none;
  font-size: 20px;
  padding-top: 8px;
  color : #fefcc6;
}

h2 {
  pointer-events:none;
  font-smooth: never;
  -webkit-font-smoothing : none;
  font-size: 20px;
  padding: 0px;
  margin:0px;
  color : #fefcc6;
}

h3 {
  font-smooth: never;
  -webkit-font-smoothing : none;
  font-size: 25px;
  color : #fefcc6;
  padding: 10px;
  margin:0px;
}

h4 {
  pointer-events:none;
  font-smooth: never;
  -webkit-font-smoothing : none;
  font-size: 30px;
  color : papayawhip;

}

.top {
  position: fixed; /* or absolute */
  width: 100%;
  top: 2%;
  left: 50%;
  align-content: center;
  align-items: center;
  text-align: center;
  horiz-align: center;
  /* bring your own prefixes */
  transform: translate(-50%, 0%);
}

.topLeft {
  position: absolute; /* or absolute */
  width: 100%;
  margin-left: 30px;
  top: 30px;
}

.main {
  position:relative;
  width:80%;
  height:auto;
  padding-top: 20px;
  margin:auto;
  overflow:visible;
}


.sound {
  position: fixed; /* or absolute */
  width: 20px;
  height:30px;
  right: 4%;
  bottom: 4%;
}

.absoluteTop {
  position: fixed; /* or absolute */
  width: 80%;
  height:40px;
  left: 50%;
  bottom: 25px;
  align-content: center;
  align-items: center;
  text-align: center;
  horiz-align: center;
  margin-top: 25px;
  /* bring your own prefixes */
  transform: translate(-50%, 0%);
}

.absoluteBottom {
  position: fixed; /* or absolute */
  width: 100%;
  height:40px;
  left: 50%;
  bottom: 4%;
  align-content: center;
  align-items: center;
  text-align: center;
  horiz-align: center;
  margin-top: 25px;
  /* bring your own prefixes */
  transform: translate(-50%, 0%);
}

.bottom {
  position: relative; /* or absolute */
  width: 100%;
  height:60px;
  left: 50%;
  align-content: center;
  align-items: center;
  text-align: center;
  horiz-align: center;
  margin-top: 25px;
  padding-bottom: 10px;
  /* bring your own prefixes */
  transform: translate(-50%, 0%);
}


.grid-container-header {
  display: grid;
  margin: 0 auto;

  grid-template-columns: auto auto auto auto;
  width: 240px;
  align-content: center;
  align-items: center;
  text-align: center;
  horiz-align: center;
  alignment: center;
  padding-top: 15px;

}

.grid-item-header {
  padding: 0px;
  font-size: 30px;
  text-align: center;
}

.fullBg{
  background-image: url("images/bg/apng.png"),url("images/bg/placeholder.png");
  box-shadow: 0 0 100px black inset;
  background-position: center;

}

.blurredBg{
  background-image: url("images/bg/blurredBg.png");
  background-position: bottom;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  left: 50%;
  align-content: center;
  align-items: center;
  text-align: center;
  horiz-align: center;

}

.roadMap {
  width: 100%;
  max-width: 1200px;
  height: auto;
  transform: translate(0%, 1%);

}

body {
  /* The image used */

  image-rendering: pixelated;

  background-image: url("images/bg/apng.png"),url("images/bg/placeholder.png");

  /* Full height */

  /* Center and scale the image nicely */
  background-position: bottom;
  background-repeat: no-repeat;

}


  .grid-item-header {
    padding: 1%;
    font-size: 30px;
    text-align: center;
  }
  .grid-container-header {
    display: grid;
    grid-template-columns: auto auto auto auto;
    width: 240px;
    float:right;
    padding: 20px;
  }
  body {
    background-attachment: fixed;
    image-rendering: pixelated;
    /* The image used */
    background-image: url("images/bg/apng.png"),url("images/bg/placeholder.png");

    /* Full height */
    /*height: 90%;*/
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


  }

  .centered {
    position: fixed; /* or absolute */
    width: 90%;
    bottom: 60%;
    left: 50%;
    align-content: center;
    align-items: center;
    text-align: center;
    horiz-align: center;
    /* bring your own prefixes */
    transform: translate(-50%, 0%);
  }
/*img {*/
/*  width:500px;*/
/*  min-width:38%;*/
/*  max-width:100%;*/
/*  box-shadow: 5px 5px;*/
/*  border-radius: 12px;*/

/*}*/
